import { createApp } from 'vue';
import { mapStores } from 'pinia';
import App from '@/app/app.vue';
import router from '@/router';
import { i18nPlugin } from '@/utils/i18n';
import filters from '@/filters';
import MixinComponent from '@/mixin-component';
import { store } from '@/stores/index';
import { useAssistantStore, assistantStoreWatcher } from '@/stores/assistant';
import { useClusterStore } from '@/stores/cluster';
import { useComitologyStore } from '@/stores/comitology';
import { useConfigStore } from '@/stores/config';
import { useCustomRouterStore } from '@/stores/custom-router';
import { useDeadlineTrackingStore } from '@/stores/deadline-tracking';
import { useFafValidationStore } from '@/stores/faf-validation';
import { useFogOverlayStore } from '@/stores/fog-overlay';
import { useLockedStore } from '@/stores/locked';
import { useMenuStore } from '@/stores/menu';
import { usePageStore } from '@/stores/page';
import { usePopupStore } from '@/stores/popup';
import { usePreviousSearchStore } from '@/stores/previous-search';
import { useProjectStore } from '@/stores/project';
import { useThirdPartyStore } from '@/stores/third-party';
import { useSidePanelStore } from '@/stores/side-panel';
import { useUserStore } from '@/stores/user';

// ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
// TailwindCSS (atomic classes are not !important)
import '@/styles/main.css';
// then thirdparty styles
import 'tippy.js/dist/tippy.css';

// ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
// dayjs locale
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ScrollWatcher, Tooltip, TextareaAutosize, Focus } from '@/directives';
dayjs.locale('fr');
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

async function init() {
  // Override side-menu background color if not in production
  if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
    document.documentElement.style.setProperty(
      '--menu-color',
      import.meta.env.VITE_BACKGROUND_COLOR ? `#${import.meta.env.VITE_BACKGROUND_COLOR}` : 'var(--menu-color-dev)'
    );
  }

  // If not production envrionment, redraw favicon
  if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
    // Get favicon link or create new link element
    let link = document.querySelector('link[rel~=\'icon\']');
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'shortcut icon');
      document.head.appendChild(link);
    }
    // Get the favicon
    const faviconUrl = link.getAttribute('href') || `${window.location.origin}/img/icons/favicon-32x32.png`;
    // Create new image
    const img = document.createElement('img');
    const onImageLoaded = () => {
      // Recolor the image
      const canvas = document.createElement('canvas');
      canvas.width = 48;
      canvas.height = 48;
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      context.drawImage(img, 0, 0);
      context.globalCompositeOperation = 'source-in';
      context.fillStyle = import.meta.env.VITE_BACKGROUND_COLOR ? `#${import.meta.env.VITE_BACKGROUND_COLOR}` : 'var(--menu-color-dev)';
      context.fillRect(0, 0, 48, 48);
      context.fill();
      link?.setAttribute('type', 'image/x-icon');
      link?.setAttribute('href', canvas.toDataURL());
    };
    img.addEventListener('load', onImageLoaded);
    // Set the new favicon
    img.src = faviconUrl;
  }
  // ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  // checking user-agent for all IE
  const isIE = /Trident|MSIE/.test(navigator.userAgent);
  if (isIE) {
    alert('Votre navigateur est trop ancien.');
    return;
  }
  // ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  // watch material-symbols-rounded loading, hiding until it's loaded
  const fonts = { 'material-symbols-rounded': 'Material Symbols Rounded' };
  const style = document.createElement('style');
  style.innerHTML = Object.keys(fonts)
    .map((k) => `.${k} { opacity: 0 !important; max-width: 24px !important; overflow: hidden !important; }`)
    .join('\n');
  document.head.appendChild(style);

  // Safeguard
  setTimeout(() => {
    if (document.head.contains(style)) document.head.removeChild(style);
  }, 15000);

  document.fonts.addEventListener('loadingdone', function(event) {
    Object.entries(fonts).forEach(([key, value]) => {
      if ((event as FontFaceSetLoadEvent).fontfaces.some((fontface) => fontface.family.includes(value))) {
        const reg = new RegExp(`\\.${key}\\s?{.*?}`, 'gs');
        style.innerHTML = style.innerHTML.replaceAll(reg, '');
      }
    });
    if (style.innerHTML.trim().length === 0 && document.head.contains(style)) {
      document.head.removeChild(style);
    }
  });
  // ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  const app = createApp(App)
    .mixin(MixinComponent)
    .directive('scroll-watcher', ScrollWatcher)
    .directive('tooltip', Tooltip)
    .directive('textarea-autosize', TextareaAutosize)
    .directive('focus', Focus)
    .use(i18nPlugin)
    .use(store)
    .use(router);
  app.config.globalProperties.$filters = filters;

  // Set stores as global properties to allow every component to use it without having to import in setup
  // Using here instead of mixin to enable use in component data()

  const stores = mapStores(
    useAssistantStore,
    useClusterStore,
    useComitologyStore,
    useConfigStore,
    useCustomRouterStore,
    useDeadlineTrackingStore,
    useFafValidationStore,
    useFogOverlayStore,
    useLockedStore,
    useMenuStore,
    usePageStore,
    usePopupStore,
    usePreviousSearchStore,
    useProjectStore,
    useSidePanelStore,
    useThirdPartyStore,
    useUserStore
  );
  // will do for us all "app.config.globalProperties.xxxStore = useXXXStore();"
  Object.keys(stores).forEach((k) => (app.config.globalProperties[k] = (stores as any)[k]()));

  // Some stores to have watcher initialized
  assistantStoreWatcher();

  app.mount('#root');
}

init();
